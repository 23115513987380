import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import '../../styles.css';
import AssetInfoView from './asset-info-view';
import ApiAssets from '../../../../api/assets';
import useNotification from '../../../../components/notification/notification';
import { clearAssets, clearCurrentAsset, selectCurrentAsset, setCurrentAsset } from '../../../../store/slices/assets';

const AssetInfo = ({
  category,
  categoryId,
  categories,
  fetchAssets,
  getConfigParams,
  setConfigParams,
  refresh,
  dashboard,
}) => {
  const { t } = useTranslation();
  const asset = useSelector(selectCurrentAsset);
  const { assetId } = useParams();
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [fieldsList, setFieldsList] = useState();
  const [nameIsEmpty, setNameIsEmpty] = useState(false);
  const [fileTypeIsEmpty, setFileTypeIsEmpty] = useState(false);
  const [categoryIdIsEmpty, setCategoryIdIsEmpty] = useState(false);
  const { openNotification } = useNotification();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { products, fileOrigins, indications, tags, intelligences, intelligenceTags, fileTypes, users } =
    getConfigParams;

  useEffect(() => {
    if (assetId && !asset.author) {
      ApiAssets.getAssetById(assetId).then(r => {
        dispatch(setCurrentAsset(r.data.asset));
      });
    }
  }, [assetId]);

  useEffect(() => {
    if (Object.keys(asset).length) {
      refresh();
    }

    if (asset.author) {
      if (!asset.id) {
        setIsEdit(true);
      }
      setOpen(true);
      setFieldsList({ ...asset });
    }
  }, [asset]);

  const normalizeTags = tagsItems =>
    tagsItems?.map(item =>
      item.isNew ? { name: item.name, id: null } : { name: item.label || item.name, id: item.value || item.id }
    ) || [];

  const assetModel = data => ({
    createdAt: null,
    deletedAt: null,
    description: null,
    downloadLink: null,
    expirationDate: null,
    fileOriginId: null,
    fileTypeId: null,
    heorResearch: null,
    link: null,
    mrcApproved: null,
    name: null,
    version: null,
    productId: null,
    rpcApproved: null,
    ...data,
    categoryId: data.categoryId || categoryId,
    products: normalizeTags(data?.products),
    tags: normalizeTags(data?.tags),
    intelligences: normalizeTags(data?.intelligences),
    intelligenceTags: normalizeTags(data?.intelligenceTags),
    indications: normalizeTags(data?.indications),
  });

  const toEditMode = () => {
    setIsEdit(true);
  };

  const toViewMode = () => {
    setIsEdit(false);
    setFieldsList({ ...asset });
  };

  const updateForm = field => {
    if (nameIsEmpty && field.name && field.name.trim().length) {
      setNameIsEmpty(false);
    }

    if (fileTypeIsEmpty && field.fileTypeId) {
      setFileTypeIsEmpty(false);
    }

    if (categoryIdIsEmpty && field.categoryId) {
      setCategoryIdIsEmpty(false);
    }

    setFieldsList({ ...fieldsList, ...field });
  };

  const onSelectChange = (name, selectValue, isMultiple, isNew) => {
    const prev = (fieldsList || {})[name] || [];

    if (isNew) {
      updateForm({ [name]: [{ ...selectValue }, ...prev] });
      if (name === 'indications') {
        setConfigParams('indications', [selectValue, ...indications]);
      }

      if (name === 'tags') {
        setConfigParams('tags', [selectValue, ...tags]);
      }

      if (name === 'intelligences') {
        setConfigParams('intelligences', [selectValue, ...intelligences]);
      }

      if (name === 'intelligenceTags') {
        setConfigParams('intelligenceTags', [selectValue, ...intelligenceTags]);
      }

      return;
    }

    if (isMultiple) {
      const isSelectedValue = prev.filter(item => (item.id || item.value) === (selectValue.id || selectValue.value))[0];
      if (isSelectedValue) {
        updateForm({
          [name]: [...prev.filter(item => (item.id || item.value) !== (selectValue.id || selectValue.value))],
        });
        return;
      }

      updateForm({ [name]: [...prev, selectValue] });
      return;
    }

    updateForm({ [name]: selectValue });
  };

  const onDropdownChanged = (name, value) => {
    updateForm({ [name]: value });
  };

  const close = () => {
    if (dashboard && fetchAssets) {
      fetchAssets();
    }
    setOpen(false);
    setNameIsEmpty(false);
    setFileTypeIsEmpty(false);
    setCategoryIdIsEmpty(false);
    toViewMode();
    dispatch(clearCurrentAsset());
    navigate(pathname.split('/').slice(0, -2).join('/'));
  };

  const isValidFields = assetData => {
    let hasError = false;
    if (!assetData.name || !assetData.name.trim().length) {
      setNameIsEmpty(true);
      hasError = true;
    }

    if (!assetData.fileTypeId) {
      setFileTypeIsEmpty(true);
      hasError = true;
    }

    if (!assetData.categoryId && !categoryId) {
      setCategoryIdIsEmpty(true);
      hasError = true;
    }

    return !hasError;
  };

  const getCategoryById = id => categories.filter(c => c.id === id)[0];

  const updateAsset = () => {
    const assetData = assetModel({ ...asset, ...fieldsList });

    const isValidData = isValidFields(assetData);

    if (!isValidData) {
      return;
    }

    const request = asset.id ? ApiAssets.update(asset.id, { ...assetData }) : ApiAssets.create({ ...assetData });

    request
      .then(r => {
        if (r.data.success) {
          openNotification({
            type: 'success',
            message: asset.id ? (
              <p>
                The asset <span style={{ fontWeight: 600 }}>{assetData.name}</span> was edited
              </p>
            ) : (
              <p>
                The asset <span style={{ fontWeight: 600 }}>{assetData.name}</span> was added
              </p>
            ),
          });
        }
      })
      .catch(() => {
        openNotification({
          type: 'error',
          message: asset.id ? (
            <p>
              Can't update asset <span style={{ fontWeight: 600 }}>{assetData.name}</span>
            </p>
          ) : (
            <p>
              Can't create asset <span style={{ fontWeight: 600 }}>{assetData.name}</span>
            </p>
          ),
        });
      })
      .finally(() => {
        toViewMode();
        refresh();
        dispatch(setCurrentAsset({}));
        dispatch(clearAssets());
        if (fetchAssets) {
          dispatch(fetchAssets(1));
        }

        if (dashboard) {
          close();
        }
      });
  };

  const items = [
    {
      key: '1',
      label: 'Delete',
    },
  ];

  const onDropdownClick = ({ key }) => {
    switch (key) {
      case '1':
        return ApiAssets.remove(asset.id)
          .then(r => {
            if (r.data.success) {
              openNotification({
                type: 'success',
                message: (
                  <p>
                    The asset <span style={{ fontWeight: 600 }}>{asset.name}</span> was deleted
                  </p>
                ),
              });
            }
          })
          .catch(() => {
            openNotification({
              type: 'error',
              message: (
                <p>
                  Can't delete asset <span style={{ fontWeight: 600 }}>{asset.name}</span>
                </p>
              ),
            });
          })
          .finally(() => {
            dispatch(clearAssets());
            if (fetchAssets) {
              dispatch(fetchAssets(1));
            }
            toViewMode();
            dispatch(setCurrentAsset({}));
          });
      default:
        return null;
    }
  };

  return (
    <AssetInfoView
      isEdit={isEdit}
      close={close}
      updateAsset={updateAsset}
      toViewMode={toViewMode}
      toEditMode={toEditMode}
      asset={asset}
      fieldsList={fieldsList}
      updateForm={updateForm}
      t={t}
      isOpen={isOpen}
      products={products}
      fileOrigins={fileOrigins}
      indications={indications}
      tags={tags}
      intelligences={intelligences}
      intelligenceTags={intelligenceTags}
      fileTypes={fileTypes}
      onSelectChange={onSelectChange}
      items={items}
      onDropdownClick={onDropdownClick}
      category={category}
      nameIsEmpty={nameIsEmpty}
      fileTypeIsEmpty={fileTypeIsEmpty}
      categoryIdIsEmpty={categoryIdIsEmpty}
      getCategoryById={getCategoryById}
      users={users}
      onDropdownChanged={onDropdownChanged}
      categories={categories}
    />
  );
};

export default AssetInfo;
