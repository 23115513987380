import { DatePicker, Form, Radio, Select } from 'antd';
import moment from 'moment';
import selectItemNormalizer from '../../../../../../common/helpers/select-item-normalizer';
import calendarDateByDayjs from '../../../../../../common/utils/calendar-date';
import DropdownTagsSelect from '../../../../../../components/dropdown-tags-select/dropdown-tags-select';

const AssetInfoFormInformation = ({
  t,
  form,
  products,
  fileOrigins,
  indications,
  onSelectChange,
  updateForm,
  tags,
  onDropdownChanged,
}) => (
  <>
    <h3>{t('assets.info.form.info')}</h3>
    <Form.Item name="rpcApproved">
      <div>
        <p>{t('assets.info.form.prc')}</p>
        <Radio.Group
          defaultValue={form.prcApproved}
          onChange={({ target }) => updateForm({ prcApproved: target.value })}
        >
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
          <Radio value={null}>N/A</Radio>
        </Radio.Group>
      </div>
    </Form.Item>
    <Form.Item name="mrcApproved">
      <div>
        <p>{t('assets.info.form.mrc')}</p>
        <Radio.Group
          defaultValue={form.mrcApproved}
          onChange={({ target }) => updateForm({ mrcApproved: target.value })}
        >
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
          <Radio value={null}>N/A</Radio>
        </Radio.Group>
      </div>
    </Form.Item>
    <Form.Item name="expirationDate">
      <div>
        <p>{t('assets.info.form.expiration')}</p>
        <div style={{ marginLeft: -11 }}>
          <DatePicker
            defaultValue={calendarDateByDayjs(form.expirationDate)}
            placeholder={t('assets.info.form.select_expiration')}
            style={{ border: 'none', width: 200 }}
            format="DD MMM YYYY"
            onChange={dd => updateForm({ expirationDate: dd ? moment(dd.$d).toISOString() : null })}
          />
        </div>
      </div>
    </Form.Item>
    <Form.Item name="fileOrigin">
      <div>
        <p>{t('assets.info.form.origin')}</p>
        <div style={{ marginLeft: -11 }}>
          <Select
            placeholder="Select file origin"
            defaultValue={selectItemNormalizer(form.fileOrigin)}
            options={selectItemNormalizer(fileOrigins)}
            onChange={id => onSelectChange('fileOriginId', id)}
            className="competitive-select"
          />
        </div>
      </div>
    </Form.Item>
    <Form.Item name="products">
      <div>
        <p>{t('assets.info.form.product')}</p>
        <div style={{ marginLeft: -11, maxWidth: 400, flexWrap: 'wrap', justifyContent: 'flex-start' }}>
          <DropdownTagsSelect
            options={products}
            onCreate={tag => onSelectChange('products', tag, false, true)}
            onChange={tag => onDropdownChanged('products', tag)}
            defaultValue={form.products}
            className="competitive-select"
          />
        </div>
      </div>
    </Form.Item>
    <Form.Item name="heorResearch">
      <div>
        <p>{t('assets.info.form.heor')}</p>
        <Radio.Group
          defaultValue={form.heorResearch}
          onChange={({ target }) => updateForm({ heorResearch: target.value })}
        >
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </div>
    </Form.Item>
    <Form.Item name="indications">
      <div>
        <p>{t('assets.info.form.indications')}</p>
        <div style={{ marginLeft: -11, maxWidth: 400, flexWrap: 'wrap', justifyContent: 'flex-start' }}>
          <DropdownTagsSelect
            options={indications}
            onCreate={tag => onSelectChange('indications', tag, false, true)}
            onChange={tag => onDropdownChanged('indications', tag)}
            defaultValue={form.indications}
            className="competitive-select"
          />
        </div>
      </div>
    </Form.Item>
    <Form.Item name="tags">
      <div>
        <p>{t('assets.info.form.tags')}</p>
        <div style={{ marginLeft: -11, maxWidth: 400, flexWrap: 'wrap', justifyContent: 'flex-start' }}>
          <DropdownTagsSelect
            options={tags}
            onCreate={tag => onSelectChange('tags', tag, false, true)}
            onChange={tag => onDropdownChanged('tags', tag)}
            defaultValue={form.tags}
            className="competitive-select"
          />
        </div>
      </div>
    </Form.Item>
  </>
);

export default AssetInfoFormInformation;
