import { Divider, Dropdown, Layout, Menu } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import CompanyBrand from './components/company-brand/company-brand';
import ProfileBar from './components/menu/profile-bar/profile-bar';
import './app.css';
import ApiAuth from './api/auth';
import { selectIsAdmin, selectUser, setUser } from './store/slices/user';
import Profile from './views/profile/profile';
import { fetchAllDomains, selectDomains } from './store/slices/domains';
import { fetchAllCategories, setCategories } from './store/slices/categories';
import menuIcon from './assets/images/custom-icons/menu.svg';
import repositoryIcon from './assets/images/custom-icons/menu/repository.svg';
import reportsIcon from './assets/images/custom-icons/menu/reports.svg';
import userManagementIcon from './assets/images/custom-icons/menu/user_management.svg';
import administrationIcon from './assets/images/custom-icons/menu/administration.svg';
import NotificationMessages from './components/notification-messages/notification-messages';

const { Content, Sider } = Layout;
function getItem(label, key, children, type, icon) {
  return {
    key,
    children,
    label,
    type,
    icon,
  };
}

const App = () => {
  const domains = useSelector(selectDomains);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUser);
  const isAdmin = useSelector(selectIsAdmin);
  const [menuItems, setMenuItems] = useState([]);
  const [userManagementItems, setUserManagementItems] = useState([]);
  const [managementKey, setManagementKey] = useState('general');
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const { hash, pathname } = useLocation();
  const token = localStorage.getItem('user_token');
  const [assetsTabKey, setAssetsTabKey] = useState();
  const [administrationMenuKey, setAdministrationMenuKey] = useState();
  const [administrationItems, setAdministrationItems] = useState([]);

  const kickUser = () => {
    dispatch(setUser({}));
    dispatch(setCategories([]));
    localStorage.removeItem('user_token');
  };

  axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 401) {
        kickUser();
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    axios.defaults.headers = { Authorization: 'Bearer ' + token };

    if (token) {
      ApiAuth.loginByToken(token)
        .then(res => {
          dispatch(setUser({ ...res.data.user, token }));
        })
        .then(() => {
          dispatch(fetchAllDomains());
          dispatch(fetchAllCategories());
        })
        .catch(() => {
          kickUser();
        });
    }
  }, []);

  useEffect(() => {
    const notEmptyAssets = domains.filter(item => item.Categories?.length);

    const domainsTree = notEmptyAssets.map(item =>
      getItem(
        item.name,
        item.id,
        item.Categories.map(doc => getItem(doc.name, doc.id)),
        'group'
      )
    );

    const search = getItem(
      '',
      null,
      [
        getItem(
          <div>
            <Divider />
            <span>{t('search')}</span>
            <Divider />
          </div>,
          'search',
          null,
          null
        ),
      ],
      'group'
    );

    setMenuItems([search, ...domainsTree]);
  }, [domains, setMenuItems]);

  useEffect(() => {
    if (pathname === '/user-management') {
      setManagementKey(hash || 'general');
      return;
    }

    setManagementKey('general');

    if (pathname === '/administration') {
      setAdministrationMenuKey(hash);
    }
  }, [pathname, hash]);

  useEffect(() => {
    if (pathname === '/assets/search') {
      setAssetsTabKey('search');
      return;
    }

    if (categoryId) {
      setAssetsTabKey(categoryId);
      return;
    }

    if (assetsTabKey) {
      setAssetsTabKey(null);
    }
  }, [pathname, categoryId]);

  useEffect(() => {
    const userManagementElements = [
      { id: 'general', name: 'System users' },
      { id: 'disabled', name: 'Disabled users' },
      { id: 'pending', name: 'Pending users' },
    ];
    setUserManagementItems(userManagementElements.map(item => getItem(item.name, item.id)));
  }, [hash]);

  useEffect(() => {
    const elements = [
      {
        label: 'System settings',
        key: 'settings',
        children: [{ id: 'notifications', name: 'Notifications' }].map(children => getItem(children.name, children.id)),
        type: 'group',
      },
      {
        label: 'List managment',
        key: 'managment',
        children: [
          { id: 'category', name: 'Category' },
          { id: 'products', name: 'Products' },
          { id: 'file_origin', name: 'File origin' },
        ].map(children => getItem(children.name, children.id)),
        type: 'group',
      },
    ];

    setAdministrationItems(
      elements.map(el => {
        const { label, key, children, type } = el;
        return getItem(label, key, children, type);
      })
    );
  }, [pathname]);

  const switchUserManagementTab = ({ key }) => {
    if (key === 'disabled') {
      navigate('/user-management#disabled');
      setManagementKey('disabled');
      return null;
    }
    if (key === 'pending') {
      navigate('/user-management#pending');
      setManagementKey('pending');
      return null;
    }

    setManagementKey('general');
    return navigate('/user-management');
  };

  const switchAdministrationTab = ({ key }) => {
    if (key) {
      navigate(`/administration#${key}`);
      setAdministrationMenuKey(key);
    }
  };

  const moveTo = ({ key }) => {
    if (key === 'search') {
      navigate('/assets/search');
      return;
    }
    navigate(`/assets/category/${key}`);
  };

  const onDropdownSelect = ({ key }) => {
    switch (key) {
      case '1':
        return navigate('/assets');
      case '2':
        return navigate('/audit-trail');
      case '3':
        return navigate('/user-management');
      case '4':
        return navigate('/administration');
      default:
        return null;
    }
  };

  const items = [
    { key: '1', label: 'Asset List', icon: <img src={repositoryIcon} alt="repository" /> },
    {
      key: '2',
      label: 'Reports',
      icon: <img src={reportsIcon} alt="reports" />,
      disabled: !isAdmin,
    },
    {
      key: '3',
      label: 'User management',
      icon: <img src={userManagementIcon} alt="user management" />,
      disabled: !isAdmin,
    },
    {
      key: '4',
      label: 'Administration',
      icon: <img src={administrationIcon} alt="administration" />,
      disabled: !isAdmin,
    },
  ];

  const assetsMenu = useMemo(
    () => (
      <Menu
        onClick={moveTo}
        selectedKeys={assetsTabKey}
        mode="inline"
        items={menuItems}
        onSelect={moveTo}
        style={{ backgroundColor: 'rgba(247, 245, 242, 1)', overflowY: 'auto', maxHeight: 'calc(100vh - 140px)' }}
      />
    ),
    [menuItems, assetsTabKey]
  );

  const userManagementMenu = useMemo(
    () => (
      <Menu
        onClick={switchUserManagementTab}
        selectedKeys={managementKey}
        mode="inline"
        items={userManagementItems}
        onSelect={switchUserManagementTab}
        style={{ backgroundColor: 'rgba(247, 245, 242, 1)' }}
      />
    ),
    [userManagementItems, managementKey]
  );

  const administrationMenu = useMemo(
    () => (
      <Menu
        onClick={switchAdministrationTab}
        selectedKeys={administrationMenuKey}
        mode="inline"
        items={administrationItems}
        onSelect={switchAdministrationTab}
        style={{ backgroundColor: 'rgba(247, 245, 242, 1)' }}
      />
    ),
    [administrationMenuKey, administrationItems]
  );

  const leftSideMenu = useMemo(() => {
    switch (pathname) {
      case '/user-management':
        return userManagementMenu;

      case '/administration':
        return administrationMenu;

      default:
        return assetsMenu;
    }
  }, [pathname, menuItems, userManagementItems, administrationItems]);

  if (!currentUser.token) {
    return null;
  }

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Sider
        theme="light"
        className="sider"
        width={301}
        style={{
          backgroundColor: 'rgba(247, 245, 242, 1)',
        }}
      >
        <div className="sider-menu-header">
          <Dropdown
            menu={{
              items,
              onClick: onDropdownSelect,
              selectable: true,
            }}
            trigger={['click']}
            overlayStyle={{ width: 245 }}
          >
            <img src={menuIcon} alt="menu" style={{ marginRight: 10, cursor: 'pointer' }} />
          </Dropdown>
          <CompanyBrand logoStyles={{ height: 40 }} nameStyles={{ fontSize: 16 }} />
        </div>

        {leftSideMenu}

        <div className="sider-menu-footer">
          <Divider style={{ margin: 0 }} />
          <ProfileBar />
        </div>
      </Sider>
      <Layout style={{ backgroundColor: '#fff', maxHeight: '100vh', overflow: 'hidden' }}>
        <Content>
          <Outlet />
        </Content>
      </Layout>
      <Profile />
      <NotificationMessages />
    </Layout>
  );
};
export default App;
