import { Spin, Table } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { clearTrails, fetchTrails, selecthasMoreTrails, selectTrails } from '../../store/slices/trails';
import AuditTrailHeader from './components/audit-trail-header';
import ApiAssets from '../../api/assets';
import './styles.css';

const AuditTrail = () => {
  const trails = useSelector(selectTrails);
  const hasMore = useSelector(selecthasMoreTrails);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [authors, setAutors] = useState();

  const fetchData = (currentPage = page) => {
    if (!isLoading) {
      setPage(currentPage + 1);
      setLoading(true);
      dispatch(
        fetchTrails({
          page: currentPage,
          ...filters,
          createdAtEnd: filters.period?.[filters.period.length - 1]?.end,
          createdAtStart: filters.period?.[filters.period.length - 1]?.start,
        })
      );
    }
  };

  const updateFilterValue = filter => {
    setFilters(filter);
  };

  useEffect(() => {
    if (trails.length) {
      setLoading(false);
    }
  }, [trails]);

  useEffect(() => {
    dispatch(clearTrails());
    fetchData(1);
  }, [filters]);

  useEffect(() => {
    ApiAssets.getAuthors().then(r => {
      setAutors(r.data.authors);
    });
  }, []);

  const columns = [
    {
      title: t('trails.table.id'),
      dataIndex: 'id',
      render: (_, trail) => <span>{trail.id || 'N/A'}</span>,
      key: 'id',
      width: '5%',
    },
    {
      title: t('trails.table.time'),
      dataIndex: 'time',
      key: 'time',
      render: (_, trail) => <span>{trail.createdAt || 'N/A'}</span>,
      width: '10%',
    },
    {
      title: t('trails.table.email'),
      dataIndex: 'email',
      key: 'email',
      render: (_, trail) => <span>{trail.userEmail || 'N/A'}</span>,
      width: '15%',
    },
    {
      title: t('trails.table.full_name'),
      dataIndex: 'full_name',
      key: 'full_name',
      render: (_, trail) => <span>{trail.userName || 'N/A'}</span>,
      width: '15%',
    },
    {
      title: t('trails.table.updated_from'),
      dataIndex: 'updated_from',
      key: 'updated_from',
      render: (_, trail) => (
        <span style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{trail.valueFrom || 'N/A'}</span>
      ),
      width: '15%',
    },
    {
      title: t('trails.table.updated_to'),
      dataIndex: 'updated_to',
      key: 'updated_to',
      render: (_, trail) => (
        <span style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{trail.valueTo || 'N/A'}</span>
      ),
      width: '15%',
    },
    {
      title: t('trails.table.action'),
      dataIndex: 'action',
      key: 'action',
      render: (_, trail) => (
        <span style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{trail.action || 'N/A'}</span>
      ),
      width: '25%',
    },
  ];

  const actionTypes = [
    { id: 'addedAsset', name: 'Asset was added' },
    { id: 'updatedAsset', name: 'Asset was updated' },
    { id: 'deletedAsset', name: 'Asset was deleted' },
    { id: 'categoryCreated', name: 'Category was created' },
    { id: 'categoryUpdated', name: 'Category was updated' },
    { id: 'categoryOrdered', name: 'Category was ordered' },
    { id: 'categoryDeleted', name: 'Category was deleted' },
    { id: 'productCreated', name: 'Product was created' },
    { id: 'productUpdated', name: 'Product was updated' },
    { id: 'productOrdered', name: 'Product was ordered' },
    { id: 'productDeleted', name: 'Product was deleted' },
    { id: 'notificationTimeUpdate', name: 'Notification time was updated' },
    { id: 'expirationNotificationsSettingsUpdated', name: 'Expiration notifications settings were updated' },
    { id: 'userAvatarUpdated', name: 'User avatar was updated' },
    { id: 'userRoleUpdated', name: 'User role was updated' },
    { id: 'userStatusUpdated', name: 'User status was updated' },
    { id: 'userFirstNameUpdated', name: 'User first name was updated' },
    { id: 'userLastNameUpdated', name: 'User last name was updated' },
    { id: 'userBlocked', name: 'User was blocked' },
    { id: 'unsuccessfulLoginAttempt', name: 'Unsuccessful login attempt' },
    { id: 'userLogged', name: 'User logged in' },
    { id: 'userInvited', name: 'User was invited' },
    { id: 'accountActivated', name: 'Account was activated' },
    { id: 'passwordReseted', name: 'Password was reset' },
    { id: 'passwordChanged', name: 'Password was changed' },
    { id: 'userDeactivated', name: 'User was deactivated' },
    { id: 'userDeleted', name: 'User was deleted' },
    { id: 'userActivated', name: 'User was activated' },
    { id: 'sentRestorePassword', name: 'Restore password was sent' },
    { id: 'userReInvited', name: 'User was reInvited' },
  ];

  const filterItems = [
    { name: 'Author', id: 'userId', children: authors },
    { name: 'Date peroid', id: 'period', period: true },
    { name: 'Action Type', id: 'actionType', children: actionTypes },
  ];

  const loader = useMemo(
    () =>
      hasMore ? (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Spin />
        </div>
      ) : null,
    [hasMore]
  );

  return (
    <div className="user-management">
      <AuditTrailHeader t={t} filterItems={filterItems} updateFilterValue={updateFilterValue} />
      <InfiniteScroll
        dataLength={trails.length}
        next={fetchData}
        hasMore={hasMore}
        loader={loader}
        height={window.innerHeight - 81}
      >
        <Table
          className="assets-list__table audit-trail__table"
          columns={columns}
          dataSource={trails}
          pagination={false}
          rowKey={trail => `${trail.id}${page}`}
          loading={!trails.length}
          rowClassName="assets-list__table__row"
          data-testid="trail-table"
        />
      </InfiniteScroll>
    </div>
  );
};

export default AuditTrail;
