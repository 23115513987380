import { Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './styles.css';
import { useSelector } from 'react-redux';
import { selectIsAdmin } from '../../store/slices/user';

const Header = ({ filters, title, search, create, apply, cancel, className }) => {
  const isAdmin = useSelector(selectIsAdmin);

  return (
    <div className={`assets-header ${className || ''}`}>
      <div className="assets-header__wrapper">
        <div className="assets-header__left">
          <h1>{title}</h1>
          <div className="assets-header__filters">{filters}</div>
        </div>
        <div className="assets-header__right">
          {search && (
            <Input
              value={search.value}
              onChange={search.onChange}
              size="large"
              placeholder={search.placeholder}
              prefix={<SearchOutlined />}
              className="assets-header__search"
            />
          )}
          {create && isAdmin && (
            <Button type="primary" className="assets-header__add-btn" onClick={create.onClick}>
              {create.label}
            </Button>
          )}
          {apply && (
            <Button type="primary" className="assets-header__apply-btn" onClick={apply.onClick}>
              {apply.label}
            </Button>
          )}
          {cancel && (
            <Button
              type="secondary"
              className="assets-header__cancel-btn"
              style={{ background: 'rgba(108, 108, 137, 0.08)' }}
              onClick={cancel.onClick}
            >
              {cancel.label}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
export default Header;
