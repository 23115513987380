import { useMemo } from 'react';
import DropdownFilters from '../../../components/dropdown-filters/dropdown-filters';
import '../styles.css';
import Header from '../../../components/header/header';

const AssetsHeader = ({
  category,
  setAsset,
  t,
  author,
  value,
  onChange,
  updateFilterValue,
  filter,
  getConfigParams,
}) => {
  const { products, fileOrigins, indications, tags, intelligences, intelligenceTags, fileTypes, authors } =
    getConfigParams;

  const items = useMemo(
    () => [
      { name: 'File Type', id: 'fileTypeId', children: fileTypes },
      { name: 'Modified Date', id: 'period', period: true },
      { name: 'Author', id: 'userId', children: authors },
      { name: 'Information' },
      { name: 'Approved for Field Use', id: 'prcApproved' },
      { name: 'Approved for Internal Use', id: 'mrcApproved' },
      { name: 'Expiration Date', id: 'expiration', period: true },
      { name: 'File Origin', id: 'fileOriginId', children: fileOrigins },
      { name: 'Products', id: 'product', children: products },
      { name: 'HEOR Research', id: 'heorResearch' },
      { name: 'Tags', id: 'tag', children: tags },
      { name: 'Additional Indications', id: 'indication', children: indications },
      { name: t('assets.info.form.competitive') },
      { name: 'Products', id: 'intelligence', children: intelligences },
      { name: 'Tags', id: 'intelligenceTag', children: intelligenceTags },
    ],
    [products, fileOrigins, indications, tags, intelligences, intelligenceTags, fileTypes, authors]
  );

  return (
    <Header
      title={category?.name ? category.name : 'Asset List'}
      filters={<DropdownFilters filtersBy={items} updateFilterValue={updateFilterValue} filter={filter} />}
      search={{
        value,
        onChange,
        placeholder: t('assets.btn.search'),
      }}
      create={{
        label: t('assets.btn.add_asset'),
        onClick: () => setAsset({ author }),
      }}
    />
  );
};

export default AssetsHeader;
